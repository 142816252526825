import React, { useRef } from "react"

import { Layout } from "@components/layout"
import { Vineyards2021 } from "@components/standard"

const Vineyards2021Page = ({ pageContext, location }) => {
  const { page } = pageContext
  return (
    <Layout {...page}>
      <Vineyards2021 />
    </Layout>
  )
}

export default Vineyards2021Page
